<template>
    <div>
    <v-row v-if="$vuetify.breakpoint.mdAndDown"  no-gutters justify="end" >
        <v-col class="text-right" cols=12>
            <v-btn class="inbox-tab-mobile" :color="selectedTab == 'inbox' ? 'primary' : ''" depressed @click="onChangeTab('inbox')">{{$t(`button.inbox`)}}</v-btn>
            <v-btn class="inbox-tab-mobile" :color="selectedTab == 'announcement' ? 'primary' : ''" depressed @click="onChangeTab('announcement')">{{$t(`button.notification`)}}</v-btn>
        </v-col>
    </v-row>
    <v-card class="desktop_card" elevation="0">
      <v-card-title class="justify-space-between" >
        <label class="--v-primary-base text-left">{{ $t(`label.inbox`) }}</label>
        <v-btn v-if="$vuetify.breakpoint.mdAndDown" icon elevation="0" class="icon-btn mt=0 mr-2" @click="deleteMsg()">
            <delete-icon> </delete-icon>
        </v-btn>
      </v-card-title>
      <v-row v-if="$vuetify.breakpoint.lgAndUp"  no-gutters justify="end" class="px-10">
            <v-col class="text-right" cols=12>
                <v-btn icon elevation="0" class="icon-btn mr-2" @click="deleteMsg()">
                    <delete-icon> </delete-icon>
                </v-btn>
                <v-btn class="inbox-tab" :color="selectedTab == 'inbox' ? 'primary' : ''" depressed @click="onChangeTab('inbox')">{{$t(`button.inbox`)}}</v-btn>
                <v-btn class="inbox-tab" :color="selectedTab == 'announcement' ? 'primary' : ''" depressed @click="onChangeTab('announcement')">{{$t(`button.notification`)}}</v-btn>
            </v-col>
        </v-row>
      <v-card class="profile-detail-card inbox-card" elevation="0">
        <v-row v-if="selectedTab == 'announcement'" no-gutters justify="start" class="px-8 pa-4">
            <v-col cols=12 v-for="(a, index) in selectedList" :key="a.id" :class="a.has_read ? 'inbox-message-isread' : ''">
            <v-row no-gutters class="py-1">
            <v-col cols="12" md=3 lg=2>
                <v-checkbox
                class="mt-0"
                v-bind:label="a.send_at | shortDate"
                color="primary"
                v-model="a.selected"
                hide-details
                ></v-checkbox>
            </v-col>
            <v-col cols="12" md=9 lg=10>
                <v-row no-gutters :class="$vuetify.breakpoint.mdAndDown? 'ml-8' : ''" @click="readInboxMsg(a.id)">
                    <v-col cols=12 :class="$vuetify.breakpoint.mdAndDown? 'my-3' : 'mb-3'">
                        <label class="font-weight-bold subtitle-1">{{a.subject}}</label>
                    </v-col>
                    <v-col cols=12>
                         <label>{{a.message}}</label>
                    </v-col>
                </v-row>
            </v-col>
            </v-row>
            <v-row no-gutters class="py-3">
            <v-col cols="12">
            <v-divider v-if="index !== announcementList.length -1"></v-divider>
            </v-col>
            </v-row>
            </v-col>
            <v-col cols=12 class="pt-1 pb-3 text-center" v-if="announcementList.length == 0">
                <label class="field-name">{{ $t(`message.noMeassage`)}}</label>
            </v-col>
            <v-col cols="12">
            <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row v-if="selectedTab == 'inbox'" no-gutters align="start" justify="start" class="px-8 pa-4">
            <v-col cols=12 v-for="(a, index) in selectedList" :key="a.id" :class="a.has_read ? 'inbox-message-isread' : ''">
            <v-row no-gutters class="py-1">
            <v-col cols="12" md=3 lg=2>
                <v-checkbox
                v-model="a.selected"
                class="mt-0"
                v-bind:label="a.send_at | shortDate"
                color="primary"
                hide-details
                ></v-checkbox>
            </v-col>
            <v-col cols="12" md=9 lg=10 @click="openMsgDialog(a)">
                <v-row no-gutters  :class="$vuetify.breakpoint.mdAndDown? 'ml-8' : ''">
                    <v-col cols=12 :class="$vuetify.breakpoint.mdAndDown? 'my-3' : 'mb-3'">
                        <label class="font-weight-bold subtitle-1">{{a.subject}}</label>
                    </v-col>
                    <v-col cols=12>
                         <label>{{a.message}}</label>
                    </v-col>
                </v-row>
            </v-col>
            </v-row>
            <v-row no-gutters class="py-3">
            <v-col cols="12">
            <v-divider v-if="index !== inboxList.length -1"></v-divider>
            </v-col>
            </v-row>
            </v-col>
            <v-col cols=12 class="pt-1 pb-3 text-center" v-if="inboxList.length == 0">
                <label class="field-name">{{ $t(`message.noMeassage`)}}</label>
            </v-col>
            <v-col cols="12">
            <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row no-gutters class="mb-6" v-if="pagination.totalPage > 1">
            <v-col cols=12>
           <v-pagination light circle class="vip_summary_pagination" v-model="pagination.currentPage" :length="pagination.totalPage" @input="changePage" :next-icon="'arrow_forward_ios'" :prev-icon="'arrow_back_ios'" :total-visible="pagination.paginationPageLimit"></v-pagination>
            </v-col>
        </v-row>
      </v-card>
    </v-card>
     <app-dialog :dialogShow="msgDialog.show" :max-width="1100" :title="msgDialog.title" :subtitle="msgDialog.subtitle"
                :closeAction="this.closeMsgDialog">
      <v-container :class="[$vuetify.breakpoint.smAndDown ? 'maxFrameHeight' :'fill-height']">
        <div v-html="msgDialog.msg" class="inbox_msg_content"></div>
      </v-container>
    </app-dialog>
    </div>
</template>
<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import deleteIcon from '@/assets/svg/delete-icon.svg'
import _ from 'lodash';
import { INBOX_GET_MSG, INBOX_READ_MSG, INBOX_DELETE_MSG } from '@/store/inbox.module'
export default {
  name: 'myInbox',
  components: {
    deleteIcon
  },
  data: () => ({
    language: uiHelper.getLanguage(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: 'inbox',
    msgDialog: {
        show: false
    },
    inboxList: [],
    announcementList: [],
    selectedList:[],
    pagination: {
        currentPage: 1,
        totalPage: 1,
        paginationPageLimit: 10,
    },
    searchCriteria: {
        pageNumber: 1,
        pageSize: 20,
        mobilePageSize: 10
      }
  }),
  created() {
      this.getInboxMsg()
  },
  computed: {
    msgRes() {
        return this.$store.state.inbox.inboxMsg;
    }
  },
  watch: {
       msgRes() {
           this.getFilterAnnoucementList()
           this.getFilterInboxList()
           this.onChangeTab(this.selectedTab) 
       }
  },
  methods: {
    getFilterAnnoucementList() {
         let self = this
         this.announcementList = _.cloneDeep(this.$store.state.inbox.inboxMsg.filter(a => {
          self.$options.filters.shortDate(a.sent_at);
          return a.category == "2"
      }))
    },
    getFilterInboxList() {
         let self = this
         this.inboxList = _.cloneDeep(this.$store.state.inbox.inboxMsg.filter(a => {
          self.$options.filters.shortDate(a.sent_at);
          return a.category == "1"
      }))
    },
    getInboxMsg() {
        this.$store.dispatch(`${INBOX_GET_MSG}`)
    },
    readInboxMsg(msgId) {
        let obj = {
            msgId: msgId
        }
        this.$store.dispatch(`${INBOX_READ_MSG}`, {obj})
        this.getInboxMsg()
    },
    openMsgDialog(selectedMsg) {
        this.msgDialog = {
            show: true,
            msg: selectedMsg.content,
            title: selectedMsg.subject,
            subtitle: selectedMsg.message
        }
        this.readInboxMsg(selectedMsg.id)
    },
    closeMsgDialog() {
      this.msgDialog = {
            show: false
        }
    },
    onChangeTab(tab) {
        this.selectedTab = tab
        if(tab == 'inbox'){
            this.renderPage(this.inboxList)
        } else {
            this.renderPage(this.announcementList)
        }
    },
    renderPage(list) {
        if (this.$vuetify.breakpoint.xsOnly) {
            this.pagination.totalPage = Math.ceil(list.length / this.searchCriteria.mobilePageSize)
        } else {
            this.pagination.totalPage = Math.ceil(list.length / this.searchCriteria.pageSize)
        }
        this.searchCriteria.pageNumber = 1
        this.changePage(1)
    },
    changePage(targetPage) {
        let list = []
        if(this.selectedTab == 'inbox'){
            list = this.inboxList
        } else {
            list = this.announcementList
        }
        this.searchCriteria.pageNumber = targetPage
        if (this.$vuetify.breakpoint.xsOnly) {
          this.selectedList = list.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.mobilePageSize, this.searchCriteria.pageNumber * this.searchCriteria.mobilePageSize)
        } else {
          this.selectedList = list.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.pageSize, this.searchCriteria.pageNumber * this.searchCriteria.pageSize)
        }
      },
    async deleteMsg(){
        let list = _.map(this.selectedList.filter(a => {
                        return a.selected
                    }), 'id')
        if(list && list.length > 0){
            let obj = {
                 msgId: list.join(",")
            }
            await this.$store.dispatch(`${INBOX_DELETE_MSG}`, {obj})
            this.getInboxMsg()
        } else {
            this.$parent.openAlert(false, locale.getMessage(`message.deleteMsgError`))
        }
    }
  }
}
</script>
<style lang="scss">
 .inbox-card {
    height: 80%;
    overflow-x: hidden;
    overflow-y: scroll;
    .inbox-message-isread label{
        color: var(--v-title_color2-base) !important;
    }
}
.inbox-tab {
    width: 150px;
    margin-right: 16px;
    padding: 10px;
    background-color: #fff;
    text-transform: capitalize;
}
.inbox-tab-mobile {
    width: 120px;
    margin-right: 10px;
    padding: 5px;
    background-color: #fff;
    text-transform: capitalize;
}
.inbox_pagination .v-pagination {
    li {
      border: none !important;
      button {
        border: none !important;
        font-size: 14px;
      }
    }
  }

.inbox_msg_content {
  font-family: 'ReadexPro-Medium';
  font-size: 14px;
  text-align: left;
}

.vip_summary_pagination .v-pagination {
    li {
      border: none !important;
      button {
        border: none !important;
        font-size: 12px;
      }
    }
  }
  
@media (max-width: 959px) {
  .inbox-card {
    height: 80%;
    overflow-x: hidden;
    overflow-y: hidden;
    .inbox-message-isread label{
        color: var(--v-title_color2-base) !important;
    }
}
}
</style>
